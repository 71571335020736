<template>
  <div class="mobile-header">
    <div class="custom-row-mobile-header">
      <div class="custom-col-mobile">
        <ul class="mobMenu-icon">
          <li @click="toAccount()">
            <b-link>
              <font-awesome-icon icon="user" />
              <span class="green-badge" v-show="isLoggedIn">
                <font-awesome-icon icon="check" style="color: white" />
              </span>
            </b-link>
          </li>
          <li @click="showModal()" class="shopping-link shopping-link-mobile">
            <b-link>
              <font-awesome-icon icon="shopping-cart" />
              <span class="green-badge">
                <strong> {{ icons[0].badgeValue }}</strong>
              </span>
            </b-link>
            <ClientOnly>
              <CartPopup class="tab-mob-show" />
            </ClientOnly>
          </li>
        </ul>
      </div>
      <div class="custom-col-mobile">
        <div class="brand-name">
          <router-link to="/">
            <b-img alt="" :src="logo" class="header_logo" />
          </router-link>
        </div>
      </div>
      <div class="custom-col-mobile text-rigth">
        <ul class="mobMenu-icon flex-end">
          <li @click="toggleWishList">
            <b-link>
              <font-awesome-icon icon="heart" />
              <span class="green-badge">
                <strong style="color: #fff">{{ wishlistLength }}</strong>
              </span>
            </b-link>
          </li>
        </ul>
      </div>
    </div>
    <ClientOnly>
      <WishlistPopup />
    </ClientOnly>
    <div class="mobile-custom-row-navbar">
      <div class="custom-col">
        <b-navbar toggleable="lg" type="light" class="bg-light-clr">
          <div class="header-top-search-mobile form-inline">
            <div class="relative">
              <label>
                <b-form @submit.prevent autocomplete="off">
                  <input
                    v-model="searchText"
                    class="cutom-form-search"
                    type="search"
                    id="input"
                    @keyup.enter="submit"
                    placeholder="Waar ben je naar op zoek?"
                    @focus="showSearchPopup"
                  />
                </b-form>
              </label>

              <b-link
                @click="clearText"
                class="search-icon"
                v-if="this.searchText.length"
              >
                <font-awesome-icon icon="times" style="color: #666" />
              </b-link>

              <b-link @click="submit" class="search-icon" v-else>
                <font-awesome-icon icon="search" style="color: #666" />
              </b-link>
            </div>
            <SearchComponent
              @handleSearch="handleSearch"
              :recommendedProducts="recommendedProducts"
              :recommendedCategories="recommendedCategories"
              :recommendedBlogs="recommendedBlogs"
              :searchSuggestions="searchSuggestions"
            />
          </div>
          <b-link class="toggle-icon" @click="addClassName">
            {{ $t("menu") }}&nbsp;
            <ClientOnly>
              <font-awesome-icon icon="bars" />
            </ClientOnly>
          </b-link>
        </b-navbar>
        <ClientOnly>
          <div :class="megaMenuClass">
            <ul class="mega-menu-list">
              <div class="mobile-menu-top-bar">
                <h6>{{ $t("menu") }}</h6>
                <span
                  >{{ $t("close") }}
                  <b-nav-item class="close-menu" @click="removeClassName">
                    <font-awesome-icon icon="times" style="font-size: 22px;" />
                  </b-nav-item>
                </span>
              </div>
            </ul>
            <div class="mobile-menu-list">
              <ul>
                <li class="" @click="removeClassName">
                  <div class="nav-item">
                    <b-link to="/" class="nav-link"> Onze Sites </b-link>
                    <div
                      class="drop-down-icon"
                      v-b-toggle="'homelogo'"
                      @click.stop
                    >
                      <div class="fas fa-chevron-right"></div>
                    </div>
                    <!-- <span><font-awesome-icon icon="chevron-right" /></span> -->
                  </div>
                  <b-collapse
                    id="homelogo"
                    accordion="my-accordion-3"
                    v-model="myAccordion3Toggle"
                    role="tabpanel"
                    class="store-switch"
                  >
                    <li
                      class="mb-2"
                      v-for="option in options"
                      v-on:click="selectOption(option)"
                      :key="option.id"
                    >
                      <b-link :href="option.url" @click="hideDropDown()">
                        <img
                          :src="option.image"
                          class="dropdown-option-image"
                          width="101"
                      /></b-link>
                    </li>
                  </b-collapse>
                </li>
                <li
                  v-for="(item, index) in menu"
                  :key="`menu-item-${index}`"
                  @click="removeClassName"
                  role="tablist"
                >
                  <div class="nav-item">
                    <b-link class="nav-link" :to="`/${item.url_path}/`">
                      {{ item.name }}
                    </b-link>
                    <div
                      class="drop-down-icon"
                      v-b-toggle="`menu-item-${index}`"
                      @click.stop
                      v-if="item.children_count != 0"
                    >
                      <div class="fas fa-chevron-right"></div>
                    </div>
                  </div>
                  <b-collapse
                    :id="`menu-item-${index}`"
                    v-model="toggleStates[index]"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <div class="submenu">
                      <ul class="sub-menu-category">
                        <li
                          role="tablist"
                          v-for="(subItem, index) in item.children"
                          :key="`menu-item-sub-${index}`"
                        >
                          <div class="sub-menu-category-wrap">
                            <b-link
                              class="nav-link"
                              :to="`/${subItem.url_path}/`"
                              >{{ subItem.name }}</b-link
                            >
                            <div
                              class="drop-down-icon"
                              v-b-toggle="`menu-item-sub-${index}`"
                              @click.stop
                              v-if="subItem.children_count != 0"
                            >
                              <div class="fas fa-chevron-right"></div>
                            </div>
                          </div>
                          <b-collapse
                            :id="`menu-item-sub-${index}`"
                            accordion="my-accordion-2"
                            role="tabpanel"
                          >
                            <ul class="sub-menu-category-item">
                              <li>Test</li>
                              <li>Test</li>
                              <li>Test</li>
                              <li>Test</li>
                            </ul>
                          </b-collapse>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                </li>
                <div @click="removeClassName">
                  <cmsBlockSimple
                    class="static-menu"
                    v-if="typeof staticMenu == 'object'"
                    :identifier="staticMenu.identifier"
                  />
                </div>
              </ul>
            </div>
          </div>
        </ClientOnly>
      </div>
    </div>
    <!-- <MijnaccountComponent class="tab-mob-show"/> -->
  </div>
</template>
<script>
import config from "@config";
import WishlistPopup from "@/esf_utrecht_bankxl/core/components/header/WishlistPopup.vue";
import SearchComponent from "@/esf_utrecht_bankxl/core/components/header/SearchComponent.vue";
import CartPopup from "@/esf_utrecht_bankxl/core/components/header/CartPopup.vue";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple.vue";
import Cart from "@storefront/core/modules/cart/mixins";
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";
import ClientOnly from "vue-client-only";

export default {
  name: "MobileHeaderComponent",
  mixins: [Cart],
  props: {
    msg: String,
  },
  components: {
    WishlistPopup,
    SearchComponent,
    CartPopup,
    cmsBlockSimple,
    ClientOnly,
  },
  data() {
    return {
      logo: "",
      megaMenuClass: "mobile-custom-mega-menu",
      active: false,
      isAccountPage: false,
      searchText: "",
      searchlTimer: null,
      searchSuggestions: [],
      recommendedProducts: [],
      recommendedBlogs: [],
      recommendedCategories: [],
      toggleStates: [],
      myAccordion3Toggle: false,
      closeSignal: {
        products: null,
        categories: null,
        blogs: null,
      },
    };
  },

  created() {
    if (this.$route.name === "account") {
      this.isAccountPage = true;
    } else {
      this.isAccountPage = false;
    }
  },
  methods: {
    addClassName() {
      this.megaMenuClass = "mobile-custom-mega-menu active-nav";
      document.body.classList.add("overflow-hidden");
      document.body.classList.add("overlay");
      return;
    },
    showModal() {
      this.$root.$emit("openCartPopup");
    },
    showSearchPopup() {
      if (this.searchText.length > 0) {
        this.$root.$emit("showSearchModal");
      }
    },
    closesSearchPopup() {
      this.$root.$emit("closeSearchModal");
    },
    showDropDown() {
      const el = document.getElementById("dropDown1");
      const classes = el.classList.value;
      if (!classes.includes("d-block")) {
        el.classList.add("d-block");
      } else {
        el.classList.remove("d-block");
      }
    },
    removeClassName() {
      this.megaMenuClass = "mobile-custom-mega-menu";
      document.body.classList.remove("overflow-hidden");
      document.body.classList.remove("overlay");
      this.toggleStates = this.menu.map(() => false);
      this.myAccordion3Toggle = false;
      return;
    },
    toAccount() {
      if (this.isAccountPage) {
        this.$root.$emit("toggleAccountSidebar");
      } else {
        this.$router.push({ path: "/account/" });
      }
    },
    closeWishPopup() {
      this.active = false;
    },
    toggleWishList() {
      this.$root.$emit("openWishList");
    },
    async getSearchPreview() {
      const controller = new AbortController();
      const { signal } = controller;
      this.closeSignal.products = controller;
      const output = await getProductsBySearchPreview(
        this.searchText,
        signal,
        10
      );
      this.closeSignal.products = null;
      this.recommendedProducts = output.items;
      this.searchSuggestions = output.search_suggestions;
    },
    async getSearchPreviewBlogs(searchText) {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.blogs = controller;
      const output = await this.$store.dispatch(
        "productSerie/getBlogsBySearchPreview",
        { filter: searchText, signal }
      );
      this.closeSignal.blogs = null;
      this.recommendedBlogs = output.items;
    },

    async getSearchPreviewCategories(searchText) {
      const controller = new AbortController();
      const { signal } = controller;

      this.closeSignal.categories = controller;
      const output = await this.$store.dispatch(
        "productSerie/getCategoriesBySearchPreview",
        { filter: searchText, signal }
      );

      this.closeSignal.categories = null;
      this.recommendedCategories = output.items;
    },
    abortAllSignals() {
      if (this.closeSignal.products !== null) {
        this.closeSignal.products.abort();
      }

      if (this.closeSignal.categories !== null) {
        this.closeSignal.categories.abort();
      }
      if (this.closeSignal.blogs !== null) {
        this.closeSignal.blogs.abort();
      }
    },
    handleSearch(searchVal = "") {
      this.searchText = searchVal;
    },
    submit() {
      if (this.searchText != "") {
        clearTimeout(this.searchlTimer);
        this.abortAllSignals();
        this.closesSearchPopup();
        this.$router.push("/search/?q=" + this.searchText);
        this.searchText = "";
      }
    },
    clearText() {
      this.searchText = "";
    },
  },
  mounted() {
    this.logo = config.logo.image;
  },
  computed: {
    icons() {
      return [
        {
          badgeValue: this.cartCount,
        },
      ];
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    menu() {
      return this.$store.getters["menu/getMenu"];
    },
    wishlistLength() {
      return this.$store.getters["wishlist/getWishQuantity"];
    },
    staticMenu() {
      let staticMenu = this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_menu_static"
      );

      if (staticMenu) {
        staticMenu.content = staticMenu.content
          .replace(/<a href=/g, "<b-link to=")
          .replace(/<a/g, "<b-link")
          .replace(/<\/a>/g, "</b-link>")
          .replace(/ href="\//g, ' to="/')
          .replace(
            /<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g,
            '<b-link$1href="$2$3</a>'
          );
      }

      return staticMenu;
    },

    storeSwitch() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_store_switch"
      );
    },
    slider() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "store_switch"
      );
    },

    options() {
      var slides = [];
      this.slider.slides.forEach((slide) => {
        slides.push({
          image: slide.media.url,
          title: slide.title,
          url: slide.link,
        });
      });
      return slides;
    },
  },
  watch: {
    searchText() {
      if (this.searchText.length > 2) {
        clearTimeout(this.searchlTimer);

        this.searchlTimer = setTimeout(() => {
          this.abortAllSignals();
          this.showSearchPopup();
          this.getSearchPreview();
          this.getSearchPreviewCategories(this.searchText);
          this.getSearchPreviewBlogs(this.searchText);
        }, 300);
      } else {
        this.closesSearchPopup();
      }
    },
    $route(to) {
      if (to.name === "account") {
        this.isAccountPage = true;
      } else {
        this.isAccountPage = false;
      }
    },
  },
};
</script>
