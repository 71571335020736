<template>
  <div class="header-top-wrapper">
    <div class="header-top-menu-wrap">
      <div class="header-top-leftmenu">
        <ul class="header-top-leftmenu-list">
          <li class="my-account-link">
            <b-link to="/account/">
              <div class="icon-wrap">
                <font-awesome-icon icon="user" />
                <span class="green-badge" v-show="isLoggedIn"
                  ><font-awesome-icon icon="check" style="color: white"
                /></span>
              </div>
              <span class="header-top-link" v-show="isLoggedIn">{{
                $t("my_account")
              }}</span>
              <span class="header-top-link" v-show="!isLoggedIn">{{
                $t("login")
              }}</span>
            </b-link>
          </li>
          <li class="shopping-link" @click="showModal">
            <b-link>
              <div class="icon-wrap">
                <font-awesome-icon icon="shopping-cart" />
                <span class="green-badge text"
                  ><strong style="color: #fff">
                    {{ icons[0].badgeValue }}
                  </strong></span
                >
              </div>
              <span class="header-top-link">{{ $t("shoppping_basket") }}</span>
            </b-link>
            <div class="desktop-show">
              <ClientOnly>
                <CartPopup />
              </ClientOnly>
            </div>
          </li>
          <li class="wishlist-link">
            <b-link @click="toWishlist">
              <div class="icon-wrap">
                <font-awesome-icon icon="heart" />
                <span class="green-badge text"
                  ><strong style="color: #fff">{{ wishQty }}</strong></span
                >
              </div>
              <span class="header-top-link">{{ $t("wishlist") }}</span>
            </b-link>
          </li>
          <li class="service-link">
            <b-link to="/klantenservice/">
              <div class="icon-wrap">
                <font-awesome-icon icon="headset" />
              </div>
              <span class="header-top-link">{{ $t("customer-service") }}</span>
            </b-link>
          </li>
        </ul>
      </div>
      <div class="header-top-logo">
        <router-link to="/">
          <b-img alt="logo" :src="logo" class="header_logo" />
        </router-link>
      </div>
      <div class="header-top-rightmenu">
        <ClientOnly>
          <cmsBlockSimple
            v-show="typeof headerTopRightBlock == 'object'"
            :identifier="headerTopRightBlock.identifier"
          />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@config";
import ClientOnly from "vue-client-only";
import CartPopup from "@/esf_utrecht_bankxl/core/components/header/CartPopup.vue";
import Cart from "@storefront/core/modules/cart/mixins";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
export default {
  name: "HeaderTop",
  mixins: [Cart],
  components: { CartPopup, cmsBlockSimple, ClientOnly },
  props: {
    msg: String,
  },
  data() {
    return {
      logo: "",
    };
  },
  methods: {
    showModal() {
      this.$root.$emit("openCartPopup");
    },
    toWishlist() {
      this.$router.push("/wishlist/");
    },
  },
  computed: {
    icons() {
      return [
        {
          badgeValue: this.cartCount,
        },
      ];
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    wishlist() {
      return this.$store.getters["wishlist/getWishlist"];
    },
    wishQty() {
      return this.$store.getters["wishlist/getWishQuantity"];
    },
    headerTopRightBlock() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_top_right_block"
      );
    },
  },
  mounted() {
    this.logo = config.logo.image;
  },
};
</script>
